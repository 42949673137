import jsPDF from "jspdf";
import "jspdf-autotable";

export const exportPdf = async (headers, sheetData, title, pageWidth = 600) => {
  const pdf = new jsPDF("l", "mm", [pageWidth, 300]);

  pdf.text(title, 14, 15);
  pdf.autoTable({
    head: [headers],
    body: sheetData,
    startY: 20,
    theme: "grid",
    margin: { top: 20 }, // Adjust the top margin if needed
    styles: {
      cellWidth: "wrap",
    },
  });

  pdf.save(`${title}.pdf`);
};

// export const exportIndividualPdf = async (payload) => {
//   const pdf = new jsPDF("l", "mm", [600, 300]);
//   let count = 0; //1 time is equal 15

//   if (payload) {
//     payload.forEach((item) => {
//       if (count == 0) {
//         pdf.text(item.title, 14, 16);
//       } else if (count >= 10) {
//         pdf.text(item.title, 14, pdf.autoTable.previous.finalY + count);
//       }
//       pdf.autoTable({
//         head: [item.header],
//         body: item.sheet_data,
//         startY: count == 0 ? 20 : pdf.autoTable.previous.finalY + count + 3,
//         theme: "grid",
//         // margin: { top: 10 }, // Adjust the top margin if needed
//         styles: {
//           cellWidth: "wrap",
//         },
//       });
//       count += 10;
//     });
//   }
//   count = 0;
//   pdf.save(`${payload ? payload[0].title + ".pdf" : "Export_data.pdf"}`);
// };
